const Login                     = () => import("@/views/Login");
const Dashboard                 = () => import("@/views/Dashboard");
const ForgotPassword            = () => import("@/views/password/Forgot");
const MatchIndex                = () => import("@/views/matches/Index");
const Match                     = () => import("@/views/matches/Show");
const MemberIndex               = () => import("@/views/members/Index");
const CoachesIndex              = () => import("@/views/coaches/Index");
const TeamsIndex                = () => import("@/views/teams/Index");

const FinancialOverview         = () => import("@/views/financial/Overview");
const FinancialTransactions     = () => import("@/views/financial/Transactions");
const ClubSettings              = () => import("@/views/settings/ClubSettings");
const AcountSettings            = () => import("@/views/account/AcountSettings");
const NotFound                  = () => import("@/views/NotFound");


const routes = [
    {
        path: '/',
        redirect: { name: 'login' }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/password/reset',
        name: 'password.forgot',
        component: ForgotPassword,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/matches',
        name: 'matches.index',
        component: MatchIndex,
    },
    {
        path: '/matches/:id',
        name: 'match.show',
        component: Match,
    },
    {
        path: '/club/members',
        name: 'members',
        component: MemberIndex,
    },
    {
        path: '/club/coaches',
        name: 'coaches',
        component: CoachesIndex,
    },
    {
        path: '/club/teams',
        name: 'teams',
        component: TeamsIndex,
    },
    {
        path: '/financial/overview',
        name: 'overview',
        component: FinancialOverview,
    },
    {
        path: '/financial/transactions',
        name: 'transactions',
        component: FinancialTransactions,
    },
    {
        path: '/settings',
        name: 'club.settings',
        component: ClubSettings,
    },
    {
        path: '/account',
        name: 'account.settings',
        component: AcountSettings,
    },
    {
        path: "/:catchAll(.*)",
        name: '404',
        component: NotFound,
    },
]

export default routes